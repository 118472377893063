﻿@import "partials/_variables.scss";
@import "a11y.scss";

@mixin bannerBtn ($btn-colour: $nrw-brand-b, $txt-colour: $nrw-text-light) {
    -webkit-appearance: none;
    background-color: $btn-colour !important;
    background-image: none;
    border: none !important;
    color: $txt-colour !important;
    font-weight: 700;
    border-radius: 3px;
    cursor: pointer;
    text-decoration: none;

    &:focus {
        background-color: $nrw-focus-bg !important;
        border-color: transparent !important;
        color: $nrw-focus-text !important;
        outline: none !important;
    }
}

#CybotCookiebotDialogDetailBulkConsentLink:hover,
#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover,
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover,
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive,
#CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover,
#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a,
#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a, #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a, #CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a,
#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink, #CybotCookiebotDialogDetailBodyContentTextAbout a {
    color: $aquaOnLightGrey !important;
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:focus,
#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a:focus,
#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a:focus,
#CybotCookiebotDialog button:focus:before,
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:focus,
#CybotCookiebotDialogDetailBulkConsentLink:focus,
#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a:focus,
#CybotCookiebotDialogNavList .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive:focus,
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive:focus,
#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a:focus,
#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a:focus {
    color: black !important;
}

#CybotCookiebotDialog button:hover:before {
    border-color: $aquaOnLightGrey !important;
}

#CybotCookiebotDialog button:focus:before {
    border-color: black !important;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper input:focus-visible + span {
    @include a11yFocusDoubleBorderCookieBotCheckbox;
}


#CybotCookiebotDialogNavList .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive:focus {
    border-bottom: 2px solid black !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    border-bottom: 2px solid $aquaOnLightGrey !important;
}

#CybotCookiebotDialog input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: $aquaOnLightGrey !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:hover {
    background-color: #26601c !important;
    opacity: 1 !important;
}

#CybotCookiebotDialogPoweredbyCybot {
    padding: 5px !important;
    width: 112px !important;
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton {
    padding-left: 10px;
}

@media screen and (max-width: #{ $nrw-desktopModalWidth }) {
    #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieTypeIntro {
        padding-left: 10px;
    }

    #CybotCookiebotDialogPoweredbyCybot {
        width: 116px !important;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogPoweredbyCybot:focus-visible, #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogPoweredbyCybot:focus {
        padding: 5px;
    }
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper input[type=checkbox].CybotCookiebotDialogBodyLevelButton {
    opacity: 0 !important;
}

#CybotCookiebotDialogBodyButtonDecline {
    background-color: black !important;
    color: white !important;
    border: none !important;
}

#CybotCookiebotDialogBodyButtonDecline:focus {
    background-color: $nrw-focus-bg !important;
    border-color: transparent !important;
    color: #0b0c0c !important;
    outline: none !important;
}

#CybotCookiebotDialogBodyEdgeMoreDetailsLink {
    padding: 0px 10px 0px 10px !important;
}

#CybotCookiebotDialogBody *, #CybotCookiebotDialogDetailBodyContent * {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 15px !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    @include bannerBtn();

    &:hover:not(:focus) {
        background-color: $nrw-button-a-hover !important;
        color: $nrw-text-light !important;
    }
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    @include bannerBtn($nrw-black, $nrw-text-light);
}

#CybotCookiebotDialogBodyContentTitle {
    font-size: 16px !important;
}

@media screen and (max-width: $nrw-mobileWidthMax) {
    #CybotCookiebotDialogBody, #CybotCookiebotDialogBodyLevelButtonsTable, #CybotCookiebotDialogDetailBody {
        max-width: 98% !important;
    }

    .CybotCookiebotDialogDetailBodyContentCookieTypeTableContainer {
        overflow-x: hidden !important;
    }

    #CybotCookiebotDialogDetailBodyContentCookieContainerTypeDetails {
        padding: 12px 2px 12px 4px !important;
    }
 }


input[type="checkbox"].CybotCookiebotDialogBodyLevelButton {
    margin-right: 0 !important;
    opacity: 1 !important;
    position: static !important;

    + label {
        background-image: none !important;
        margin-left: 4px !important;
        margin-top: 1px !important;
        padding: 2px !important;
    }
}