﻿@import "partials/_variables.scss";

@mixin a11yFocusDoubleBorder ($background-color: $nrw-focus-bg-white, $border: $nrw-focus-inner-double-border) {
    border: $border !important;
    background-color: $background-color;
    box-shadow: $nrw-focus-white-shadow;
    outline: $nrw-focus-border-width solid $nrw-focus-bg;
}

@mixin a11yFocusDoubleBorderCookieBotCheckbox ($background-color: $nrw-focus-bg-white, $border: $nrw-focus-inner-double-border) {
    box-shadow: $nrw-focus-black-shadow;
    outline: $nrw-focus-border-width solid $nrw-focus-bg !important;
}

a[href],
area[href],
input:not([disabled]),
button:not([disabled]),
iframe,
[tabindex],
[contentEditable=true] {
    &:not([tabindex='-1']) {
        &:not([type='radio']):not([type='checkbox']) {
            &:not([type='text']):not([type='search']) {
                &:not(textarea) {

                    &:focus {
                        background-color: $nrw-focus-bg !important;
                        border-color: transparent !important;
                        box-shadow: $nrw-focus-shadow !important;
                        color: $nrw-focus-text !important;
                        outline: none !important;

                        * {
                            &:not(.hoverText) {
                                &:not(.triangle-down):not(.imageContainer) {
                                    color: $nrw-focus-text !important;
                                    background-color: $nrw-focus-bg !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//normal radios and checkboxes
input[type=radio]:focus,
input[type=checkbox]:focus {
    background-color: $nrw-focus-bg !important;
    border-color: transparent !important;
    color: $nrw-focus-text !important;
    outline: none !important;
    position: absolute;

    + label {
        background-color: $nrw-focus-bg !important;
        border-color: transparent !important;
        box-shadow: $nrw-focus-shadow !important;
        color: $nrw-focus-text !important;
        outline: none !important;
        padding: 10px 10px 10px 25px;
        border-radius: 5px;
    }
}
//radio buttons on forms
.radio label:focus-within {
    background-color: $nrw-focus-bg !important;
    border-color: transparent !important;
    box-shadow: $nrw-focus-shadow !important;
    color: $nrw-focus-text !important;
    outline: none !important;

    input {
        background-color: $nrw-focus-bg !important;
        border-color: transparent !important;
        color: $nrw-focus-text !important;
        outline: none !important;
    }
}

.dataconsent input:focus {
    background-color: $nrw-focus-bg !important;
    border-color: transparent !important;
    color: $nrw-focus-text !important;
    outline: none !important;

    + label {
        background-color: $nrw-focus-bg !important;
        border-color: transparent !important;
        box-shadow: $nrw-focus-shadow !important;
        color: $nrw-focus-text !important;
        outline: none !important;
    }
}
//radio buttons on forms in IE/Edge
.focus-within {
    background-color: $nrw-focus-bg !important;
    border-color: transparent !important;
    box-shadow: $nrw-focus-shadow !important;
    color: $nrw-focus-text !important;
    outline: none !important;

    input {
        background-color: $nrw-focus-bg !important;
        border-color: transparent !important;
        color: $nrw-focus-text !important;
        outline: none !important;
    }
}
// Focus Double Border.
input[type=text],
input[type=search],
textarea,
select {
    border-radius: 0 !important;

    &:focus {
        @include a11yFocusDoubleBorder;
    }
}


.imgFocusDoubleBorder {
    &:focus {
        div.imageContainer {
            @include a11yFocusDoubleBorder(white, none);
        }

        h2 {
            background-color: $nrw-focus-bg !important;
            border-color: transparent !important;
            box-shadow: $nrw-focus-shadow !important;
            color: $nrw-focus-text !important;
            outline: none !important;

            * {
                color: $nrw-focus-text !important;
                background-color: $nrw-focus-bg !important;
            }
        }
    }
}