@import "_variables.scss";

.siteNav {
    transition: 0.2s;

    &--isHovered {
        background: $nrw-bg-mid;
    }

    .siteNavLinks {
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: relative;
        list-style: none;

        .siteNavLink {
            flex-grow: 1;
            text-align: center;

            > a {
                height: 100%;
                width: 100%;
                font-weight: 700;
                position: relative;
                text-align: center;
                z-index: 3;

                span {
                    display: inline-block;
                    line-height: 1em;
                    position: relative;
                    vertical-align: middle;

                    &:after {
                        background: $nrw-brand-a;
                        bottom: -12px;
                        content: '';
                        display: block;
                        height: 5px;
                        left: -8px;
                        position: absolute;
                        right: -8px;
                        transition: 0.2s;
                        visibility: hidden;
                        transform: scaleX(0);
                        transform-origin: left center;
                        transition: visibility 225ms ease-out 225ms, -webkit-transform 225ms ease-out;
                        transition: transform 225ms ease-out, visibility 225ms ease-out 225ms;
                        transition: transform 225ms ease-out, visibility 225ms ease-out 225ms, -webkit-transform 225ms ease-out;
                        -ms-transform: scaleX(0);
                        -webkit-transform: scaleX(0);
                        -ms-transform-origin: left center;
                        -webkit-transform-origin: left center;
                        -webkit-transition: visibility 225ms ease-out 225ms, -webkit-transform 225ms ease-out;
                    }
                }

                &:not(.button) {
                    color: $nrw-text;
                    display: inline-block;
                    letter-spacing: -0.25px;
                    line-height: 75px;
                    padding: 0 15px;
                }

                &.button {
                    span {
                        &::after {
                            bottom: -22px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $nrw-tabletWidthMax) {
        background: $nrw-bg-dark;
        display: none;

        &--isHovered {
            background: $nrw-bg-dark;
        }

        &.siteNav--isOpen {
            display: block;

            .siteNavLinks {
                max-height: 100%;
            }
        }

        .siteNavLinks {
            display: block;
            margin: 0;
            max-height: 0;
            overflow: hidden;
            padding: 0;
            transition: all 0.3s ease-out;
            -webkit-transition: all 0.3s ease-out;
            width: 100%;

            .siteNavLink {
                border-bottom: 1px solid $nrw-bg-light;
                margin: 0 15px;

                &:last-of-type {
                    border: 0;
                }

                a {
                    font-weight: 400;
                    margin: 5px 0;
                    padding: 8px 15px;

                    span {
                        &:after {
                            display: none;
                        }
                    }

                    &:not(.button) {
                        color: $nrw-text-light;
                        display: block;
                        letter-spacing: -0.025em;
                        line-height: 1;
                        margin: 0;
                        padding: 14px 0;
                        text-align: left;
                    }

                    &:focus {
                        text-decoration: underline;

                        span {
                            text-decoration: underline;
                        }
                    }

                    &:focus {
                        background: transparent;
                        box-shadow: $nrw-focus-shadow;
                        color: $nrw-text-light;
                        outline: 3px solid $nrw-focus-bg;
                        text-decoration: none;

                        span {
                            text-decoration: none;
                        }
                    }
                }

                &.siteNavLink--isOpen {
                    background: transparent;

                    &::after {
                        transform: rotate(180deg);
                    }
                }

                &--hasLowerLevel {
                    position: relative;

                    &::after {
                        background: url(https://naturalresources.wales/images/caret-down.svg);
                        content: '';
                        display: block;
                        height: 14px;
                        position: absolute;
                        right: 0;
                        top: 15px;
                        transform-origin: center center;
                        -ms-transform-origin: center center;
                        -webkit-transform-origin: center center;
                        transition: -webkit-transform 225ms ease-out;
                        transition: transform 225ms ease-out;
                        transition: transform 225ms ease-out, -webkit-transform 225ms ease-out;
                        -webkit-transition: -webkit-transform 225ms ease-out;
                        width: 18px;
                    }
                }
            }

            .navButton {
                margin: 0 15px;

                .button {
                    padding: 5px 15px;
                    margin: 5px 0;
                }
            }
        }
    }

    @media screen and (min-width: $nrw-tabletLandscapeWidth) {
        .siteNavLinks {
            .siteNavLink {
                > a:hover {
                    border-color: transparent;

                    span:after {
                        visibility: visible;
                        transform: scaleX(1);
                        transition-delay: 0s, 0s;
                        -ms-transform: scaleX(1);
                        -webkit-transform: scaleX(1);
                        -webkit-transition-delay: 0s, 0s;
                    }
                }
            }
        }
    }
}
