html {
    box-sizing: border-box;
    font-family: 'HCo Gotham Rounded SSm', Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.flexBreak {
    flex-basis: 100%;
    height: 0;
}
