﻿@import "_variables.scss";

#PrintPage {
    cursor: pointer;
}

@media screen and (min-width: $nrw-mobileWidthMax) {
    .feedbackSection .feedback {
        float: left;
    }

    .feedbackSection .print {
        float: right;
    }
}

@media screen and (max-width: $nrw-mobileWidthMax) {
    .feedbackSection .print {
        margin-top: 15px;
    }

    .feedbackSection .feedback a {
        white-space: nowrap;
    }
}
