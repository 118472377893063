﻿@import "_variables.scss";

#skiplink-container {
    display: flex;
}

#cookielink-container {
    display: flex;
}

#skip-inpagenav {
    margin-bottom: 10px;
}

@media screen and (max-width: $nrw-mobileWidthMax) {
    #cookielink-container {
        display: none;
    }

    .skiplink {
        position: absolute;
        margin-left: 8px;
        line-height: 45px;
        z-index: 1;

        &.inpage {
            position: relative;
            float: none !important;
            margin-left: 0px;
            line-height: unset;
            font-weight: unset !important;
        }

        .skiplink:focus {
            background-color: $nrw-focus-bg;
            border-color: transparent;
            box-shadow: $nrw-focus-shadow;
            color: $nrw-focus-text;
        }
    }
}

@media screen and (min-width: $nrw-tabletWidth) {
    .skiplink {
        position: absolute;
        left: -9999em;
        text-decoration: none;
        outline: none;
    }

    .skiplink:focus {
        left: 0;
        z-index: 1;
        background-color: $nrw-focus-bg;
        border-color: transparent;
        box-shadow: $nrw-focus-shadow;
        color: $nrw-focus-text;
        margin: 3px;
        padding: 3px;
        position: relative;

        &.inpage {
            margin: 0px;
            padding: 0px;
            float: none !important;
        }
    }
}