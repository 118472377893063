@import "_variables.scss";

.siteHeader {
    background: $nrw-bg-mid;
    border-bottom: 1px solid $nrw-border-mid;
    display: flex;
    margin: 0;
    transition: 225ms ease-out;
    -webkit-transition: 225ms ease-out;

    .siteHeaderLogo {
        margin-right: auto;

        img {
            height: 45px;
            margin: 9px 0;
            vertical-align: middle;
            width: 257px;
        }

        &:hover,
        &:focus {
            border-color: transparent;
        }

        &:focus {
            background: transparent;
            outline: 3px solid $nrw-focus-bg;
        }
    }

    .siteHeaderConstrained {
        display: flex;
    }

    .siteHeaderSearchContainer {
        align-self: center;
        display: flex;
        flex-grow: 2;
        padding: 0 20px;

        form {
            margin: 0;
            padding: 0;
            width: 100%;

            .searchBoxContainer {
                .searchBox {
                    border: 1px solid $nrw-bg-dark;
                    border-right: 0;
                }

                .searchBoxClear {
                    position: absolute;
                }

                .searchBoxIcon {
                    border-radius: 0 $nrw-btn-br $nrw-btn-br 0;
                }
            }
        }
    }

    .siteHeaderLinks {
        align-self: center;
        margin-left: auto;
        float: right;

        ul {
            li {
                display: inline-block;
                margin: 0 0 0 20px;

                a {
                    color: $nrw-link-on-bg;
                    font-size: 15px;
                    font-weight: 400;

                    &:focus,
                    &:hover {
                        border-color: $nrw-link-on-bg-hover;
                        color: $nrw-link-on-bg-hover;
                    }
                }

                &:first-of-type {
                    margin: 0;
                }
            }
        }
    }

    .siteHeaderMobileActions {
        display: none;
        margin: 0 -15px 0 0;

        .siteHeaderSearchToggle,
        .siteHeaderNavToggle {
            border-radius: 0;
            font-size: 0;
            height: 100%;
            width: 56px;
        }

        @media screen and (max-width: $nrw-mobileWidthMax) {
            margin: 0;
        }
    }

    @media screen and (max-width: $nrw-tabletWidthMax) {
        background: $nrw-bg-light;

        .siteHeaderLogo {
            margin-left: 0px;

            img {
                height: 38px;
                width: 215px;
            }
        }

        .siteHeaderConstrained {
            display: inline-table;
        }

        .siteHeaderSearchContainer {
            background: $nrw-brand-b;
            display: none;
            margin: 0px 0px;
            padding: 10px;
            position: absolute;
            top: 61px;
            width: 100%;
            z-index: -1;
        }

        .siteHeaderLinks {
            margin: 0 10px;
            float: none;
        }

        .siteHeaderMobileActions {
            display: inline-flex;
        }

        &--searchIsOpen {
            margin: 0 0 62px;

            .siteHeaderSearchContainer {
                display: block;
                z-index: 1;
            }
        }
    }

    @media screen and (max-width: $nrw-mobileWidthMax) {
        .siteHeaderLogo {
            margin-left: 10px;
        }

        .constrainingContainer {
            flex-wrap: wrap;
        }

        .siteHeaderSearchContainer {
            top: 99px;
        }

        .siteHeaderLinks {
            order: -1;
            flex-basis: 100%;
            text-align: right;

            ul {
                border-bottom: 2px solid $nrw-border-mid;
                margin: 0 -25px;
                padding: 0 15px;

                li {
                    a {
                        display: inline-block;
                        font-size: 14px;
                        padding: 10px 0;
                    }
                }
            }
        }
    }
}
