﻿@import "_variables.scss";

.GlobalBanner {
    background-color: $nrw-bg-banner-dark;

    a {
        color: $nrw-text-banner-light;
    }

    .BannerTextContainer {
        padding: 10px 20px;

        .BannerHeading {
            font-size: 21pt;
            color: $nrw-text-banner-light;
        }

        .BannerSubHeading {
            font-size: 12pt;
            color: $nrw-text-banner-light;
            padding-top: 3px;
            display: table;
        }
    }
}