@import "_variables.scss";

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    font-family: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

ol,
ul {
    margin-left: 18px;
    &.columns-2 {
        margin: 0;
        column-count: 2;
        -moz-column-count: 2;
        -webkit-column-count: 2;
        li {
            break-inside: avoid;
        }
        @media screen and (max-width: $nrw-tabletWidthMax) {
            column-count: 1;
            -moz-column-count: 1;
            -webkit-column-count: 1;
        }
    }
    &.columns-3 {
        margin: 0;
        column-count: 3;
        -moz-column-count: 3;
        -webkit-column-count: 3;
        li {
            break-inside: avoid;
        }
        @media screen and (max-width: $nrw-tabletWidthMax) {
            column-count: 1;
            -moz-column-count: 1;
            -webkit-column-count: 1;
        }
    }
}

nav a:not(.siteFooterLink):not(.navBtn) {
    border-bottom: 1px solid transparent;
    text-decoration: none;

    span {
        color: inherit;
        text-align: inherit;
    }

    &:hover {
        border-color: $nrw-link-hover;
        outline: none;
    }
}

nav .navButton span {
    font-weight: bold;
}

button,
.button {
    background: $nrw-button-a;
    border: 0;
    border-radius: $nrw-btn-br;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    padding: 13px 15px;
    text-align: center;

    span {
        color: inherit;
    }

    &:hover {
        background-color: $nrw-button-a-hover;
        color: $nrw-text-light;
    }

    &--icon {
        background-position: center center;
        background-repeat: no-repeat;
        font-size: 0;
        height: 44px;
        width: 44px;

        &.icon--search-light {
            background-image: url(/images/search-icon.svg);
            background-size: 20px 20px;

            @media screen and (max-width: $nrw-tabletWidthMax) {
                background-image: url(/images/search-icon-green.svg);
            }

            &:focus {
                background-image: url(/images/search-icon.svg);
            }
        }

        &.icon--search-toggle {
            background-color: transparent;
            background-image: url(/images/search-icon-green.svg), url(/images/close-icon.svg);
            background-position: center center, center 80px;
            background-repeat: no-repeat;
            background-size: 26px 26px, 26px 26px;
            transition: background-position 225ms ease-out, background-color 225ms ease-out;
            -webkit-transition: background-position 225ms ease-out, background-color 225ms ease-out;

            &.active {
                background-color: $nrw-button-a;
                background-position: center -26px, center center;
            }

            &:focus {
                background-color: $nrw-focus-bg;
                background-image: url(/images/search-icon-green.svg), url(/images/close-icon.svg);
            }
        }

        &.icon--nav-toggle {
            background-color: transparent;
            background-image: url(/images/menu-icon.svg), url(/images/close-icon.svg);
            background-position: center center, center 80px;
            background-repeat: no-repeat;
            background-size: 26px 26px, 26px 26px;

            &.active {
                background-color: $nrw-bg-dark;
                background-position: center -26px, center center;
            }

            &:focus {
                background-color: $nrw-focus-bg;
                background-image: url(/images/menu-icon.svg), url(/images/close-icon.svg);
            }
        }

        &.icon--fa {
            background: transparent;
            color: $nrw-button-a;
            font-size: 24px;
            line-height: 1;
            position: relative;

            .fa {
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

input {
    &:hover {
        border-color: $nrw-link-hover;
        color: $nrw-link-hover;
        outline: none;
        text-decoration: none;
    }
}

h3 {
    font-size: 24px;
    font-weight: bold;
    color: $nrw-text;
}