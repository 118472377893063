@import "partials/_variables.scss";

/*Dt Info*/
#TableContainer .dt-info {
    clear: both;
    float: left;
    padding-top: .755em;
}

/*Dt Page Length*/
#TableContainer .dt-length {
    padding-top: .55em;
    padding-left: 2em;
    float: left;
}

/*Dt Paging*/
#TableContainer .dt-paging {
    margin-bottom: 5px;
    float: right;
    text-align: right;
    padding-top: .25em;
}

.dt-paging button {
    font: inherit;
    font-weight: 700;
}

table.dataTable .dt-column-title {
    font-weight: 700;
}

#TableContainer .bottom .clear {
    clear: both;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control::before {
    content: "+" !important;
    display: inline !important;
    font-weight: 500;
    font-size: x-large;
    color: white;
    background-color: $nrw-brand-a !important;
    border: none !important;
    border-radius: 3px;
    padding: 0em 0.2em !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.dt-hasChild.dtr-expanded > td.dtr-control::before {
    content: "-" !important;
    border: none !important;
    padding: 0 0.31em !important;
}

@media screen and (max-width: $nrw-mobileWidthMax) {
    #TableContainer .top .dt-paging {
        display: none;
    }

    #TableContainer .dt-info {
        float: none;
        text-align: center;
    }

    #TableContainer .dt-length {
        margin-bottom: 5px;
        padding-left: 2em;
        float: none;
        text-align: center;
    }
}

    