$nrw-brand-a: #008393;
$nrw-brand-b: #358728;
$nrw-black: #000000;
$nrw-text: #58595b;
$nrw-text-mid: #333;
$nrw-text-light: #F4F4F4;
$nrw-text-banner-light: #FFD33B;
$nrw-link: $nrw-brand-a; // this is wrong
$nrw-link-hover: darken($nrw-link, 10%);
$nrw-link-on-bg: $nrw-text;
$nrw-link-on-bg-hover: darken($nrw-link-on-bg, 10%);
$nrw-button-a: #358728;
$nrw-button-a-hover: darken($nrw-button-a, 10%);
$nrw-bg-light: #FFF;
$nrw-bg-mid: #E9E9EA;
$nrw-bg-mid-hover: darken($nrw-bg-mid, 10%);
$nrw-bg-dark: #58595B;
$nrw-bg-dark-alt: #4A4B4C;
$nrw-bg-banner-dark: #343434;
$nrw-border-mid: #E4E4E5;
$nrw-focus-bg: #FD0;
$nrw-focus-bg-white: #ffffff;
$nrw-focus-shadow: 0 -2px #FFDD00, 0 4px #0B0C0C;
$nrw-focus-white-shadow: 0 0 0 5px #ffffff;
$nrw-focus-black-shadow: 0 0 0 5px black;
$nrw-focus-border-width: 3px;
$nrw-focus-text: #0B0C0C;
$nrw-focus-inner-double-border: black 3px solid;
$nrw-btn-br: 3px;
$nrw-input-br: 3px;
$nrw-input-border: #d7d7d8;
$nrw-mobileWidth: 360px;
$nrw-mobileWidthMax: 767px;
$nrw-tabletWidth: $nrw-mobileWidthMax+1;
$nrw-tabletWidthMax: 1199px;
$nrw-tabletLandscapeWidth: $nrw-tabletWidthMax+1;
$nrw-tabletLandscapeWidthMax: 1199px;
$nrw-desktopWidth: $nrw-tabletLandscapeWidth+1;
$nrw-desktopModalWidth: 1280px;
$aquaOnLightGrey: #007485;