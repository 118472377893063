﻿
.cta {
    padding-right: 6px !important;
    margin-bottom: 6px !important;
}

.ctaPadded {
    @extend .cta;
    padding-left: 6px !important;
}

.ctaPaddedLeft {
    @extend .cta;
    padding-left: 0px !important;
}

.ctaInline {
       display: inline-block;
}

.ctaButton {
    margin-top: 8px !important;
}

.ctaSvg {
    transform: rotateZ(-90deg);
    background-image: url(/images/caret-down.svg);
    padding-top: 6px;
    padding-bottom: 6px;
    white-space: nowrap;
    font-weight: bold;
    line-height: 1;
    padding-right: 32px;
    background-size: 18px 18px;
    background-position: calc(100% - 8px) center;
    background-repeat: no-repeat;
    bottom: 15px;
    left: 8px;
    right: 8px;
    z-index: 2;
}

.button {
    &:focus {
        .ctaSvg {
            background-image: url(/images/caret-down-black.svg) !important;
        }
    }
}

.ctaBold {
    font-weight: bold !important;
    vertical-align: middle;
}
