﻿

.iframeContent {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
    }
}
